export const queryKeys = {
  //example
  // user: ['user'] as const,
  // memberStatus: (email: string) => ['memberStatus', email] as const,
  funeralList: (query: any) => ["funeralList", query] as const,
  funeralDirector: (id: string) => ["funderalDirector", id] as const,
  funeralDirectorReview: (id: number) =>
    ["funderalDirectorReview", id] as const,
  funeralDirectorDetail: (id: number) => ["funeralDirectorDetail", id] as const,
  anchiFacility: (id: any) => ["anchi_facilities", id] as const,
  jangjiRecommendRequest: ["jangjiRecommendRequest"],
  review: (query: string) => ["reviewDetail", query] as const,
  question: (id?: number) => ["question", id] as const,
  sangjoScrap: ["sangjoScrap"],
  sangjoReviews: (id: string) => ["sangjoReview", id] as const,
  sangjoQuotation: ["sangjoQuotation"],
  sangjoConsult: (id: string) => ["consult", id],
  sangjoConsultService: (id: string, packageId: string) => [
    "consultService",
    id,
    packageId,
  ],
  nightTime: () => ["nightTime"],
  reviews: (page: number) => ["reviews", page],
  bestReviews: ["bestReviews"],
  sangjoConsultMessage: (id: string) => ["consultMessage", id],
  myReview: ["my", "review"],
  myArticle: ["my", "articles"],
  myQuestion: ["my", "questions"],
  hospiceSearchResult: (value: string, sido?: number, current?: string) =>
    ["hospiceSearchResult", value, sido, current] as const,
  hospiceAutocomplete: (keyword: string) => ["hospiceAutocomplete", keyword],
  partnersConsult: (id: string) => ["partners", "consults", id] as const,
  partnersConsultCallSummary: ["partners", "consults", "call-summary"],
  partnersConsultInit: ["partners", "consults", "init"],
  partnersConsultCall: (id: string) =>
    ["partners", "consultsCall", id] as const,
  partnersConsultMessage: (id: string) => ["partners", "message", id] as const,
  partnersConsultService: (id: string) => ["partners", "service", id] as const,
  operator: (name: string) => ["operator", name] as const,
  adminUser: ["adminUser"],
  parlorListInBugoForm: (keyword: string, page: number) => [
    "parlorListInBugoForm",
    keyword,
    page,
  ],
  userBugoData: (id: string) => ["userBugoData", id],
  addresses: (query: string) => ["addresses", query],
  encData: ["encData"],
  monthlyPayCustomers: (id: string) => ["monthlyPay", id],
  virtualAccount: (key: string) => ["virtualAccount", key],
};
