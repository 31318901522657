export type ServiceCategory = "차량" | "인력" | "용품" | "기타";

export const SERVICE_ITEMS: Map<ServiceCategory, string[]> = new Map([
  ["차량", ["엠뷸런스", "장의차량", "리무진", "기타(차량)"]],
  ["인력", ["장례지도사(인건비)", "접객도우미", "기타(인력)"]],
  [
    "용품",
    [
      "관",
      "수의",
      "유골함",
      "입관꽃",
      "상복(남자)",
      "상복(여자)",
      "헌화",
      "기타(용품)",
    ],
  ],
  ["기타", ["기타"]],
]);
