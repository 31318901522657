import axios, { AxiosError as AxiosErrorType } from "axios";
import { captureException, SeverityLevel } from "@sentry/nextjs";

/**
 * HTTP 표준응답코드에 따른 에러 이름 매핑
 */
const ERROR_NAME_MAP: Record<number, string> = {
  400: "Bad Request",
  401: "Unauthorized",
  403: "Forbidden",
  404: "Not Found",
  405: "Method Not Allowed",
  500: "Internal Server Error",
  503: "Service Unavailable",
};

/**
 * HTTP 표준응답코드에 따라 에러 이름을 반환하는 함수
 * @param status HTTP 표준응답 코드
 * @returns 에러 이름
 */
const getErrorName = (status?: number): string => {
  console.log("status", status);
  if (status && ERROR_NAME_MAP[status]) {
    return ERROR_NAME_MAP[status];
  }
  return status ? `[${status}] Api Error` : "Api Error";
};

/**
 * 커스텀 Axios 에러 객체를 생성하는 함수
 * @param error Axios 에러 객체
 * @param message 커스텀 메시지 (선택사항)
 * @returns 커스텀 에러 객체
 */
export const createSentryAxiosError = <T = unknown>(
  error: AxiosErrorType<T>,
  message?: string
) => {
  const result = {
    name: getErrorName(error.response?.status),
    message: message ?? error.message,
    stack: error.stack,
    config: error.config,
    code: error.code,
    request: error.request,
    response: error.response,
    isAxiosError: error.isAxiosError,
    toJSON: error.toJSON,
  };
  console.log(result);
  return result;
};

interface ErrorParams {
  name?: string;
  message?: string;
  extraData?: Record<string, any>;
  level?: SeverityLevel;
}

export class CustomError extends Error {
  constructor(error: ErrorParams) {
    super(error.name);
    this.name = error.name || "";
    this.message = error.message || "";
  }
}

/**
 * 에러를 기록합니다.
 * @param { ErrorParams } 로깅할 에러에 대한 정보가 담긴 객체입니다.
 */
export const sentryLogError = ({
  name = "default Error Name",
  message = "default Error Message",
  level = "error",
  extraData,
}: ErrorParams) => {
  captureException(
    new CustomError({
      name,
      message,
    }),
    {
      level,
      extra: extraData ? { ...extraData } : undefined,
    }
  );
};

/**
 * API 에러를 기록합니다.
 * @param { ErrorParams } 로깅할 에러에 대한 정보가 담긴 객체입니다.
 */
export const sentryApiLogError = ({
  name,
  message,
  axiosError,
  extraData,
}: {
  name?: string;
  message?: string;
  axiosError: AxiosErrorType;
  extraData?: { [key: string]: any };
}) => {
  const error = createSentryAxiosError(axiosError);
  // 에러 객체를 안전하게 직렬화할 수 있는 형태로 변환
  const errorInfo = {
    name: error?.name || name || "default name",
    message: error?.message || message || "default message",
    status: error.response?.status,
    statusText: error.response?.statusText,
    url: error.config?.url,
    method: error.config?.method,
    requestData: error.config?.data,
    responseData: error.response?.data,
    ...extraData,
  };

  axiosError.name = name || error?.name;
  axiosError.message = message || error?.message;

  captureException(axiosError, {
    level: "error",
    extra: extraData ? { ...errorInfo, ...extraData } : errorInfo,
  });
};

/**
 * 에러 타입을 판단하여 적절한 에러 로깅 함수를 호출합니다.
 * @param error 로깅할 에러 객체
 */
export const handleSentryError = ({
  name,
  message,
  extraData,
  error,
}: {
  name?: string;
  message?: string;
  extraData?: Record<string, any>;
  error: unknown;
}) => {
  if (axios.isAxiosError(error)) {
    return sentryApiLogError({ name, message, axiosError: error, extraData });
  }

  const newError = error as Error;

  return sentryLogError({
    name: name || newError.name,
    message: message || newError.message,
    extraData,
  });
};
