import { ChangeEvent, HTMLAttributes } from "react";
import styled from "@emotion/styled";
import React from "react";
import { HStack } from "./HStack";
import { ColorIcon } from "./Icons/ColorIcon";
import { colors } from "goi_common";
import { css } from "@emotion/react";

interface BaseProps {
  index?: number | string;
  checked: boolean;
  checkedColor?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  size?: number;
  disabled?: boolean; // 명시적으로 추가
}

type Props = BaseProps &
  Omit<HTMLAttributes<HTMLInputElement>, keyof BaseProps>;

export const Checkbox = ({
  index,
  checked,
  checkedColor,
  label,
  disabled,
  size = 24,
  ...rest
}: Props) => {
  const id = `checkbox-${index ?? 0}`;

  return (
    <>
      <HiddenInput
        type="checkbox"
        checked={checked}
        id={id}
        disabled={disabled}
        {...rest}
      />
      <StyledLabel htmlFor={id}>
        <HStack gap={7} alignItems="center">
          <ColorIcon
            name={checked ? "check_fill" : "check_empty"}
            size={size}
            color={checked ? checkedColor || colors.orange650 : colors.gray400}
            css={css`
              path {
                fill: ${checked ? "white" : colors.gray400} !important;
              }
            `}
          />
          <div className="subtitle_14_sb black">{label}</div>
        </HStack>
      </StyledLabel>
    </>
  );
};

const HiddenInput = styled.input`
  display: none;
`;

const StyledLabel = styled.label`
  width: fit-content;
`;
